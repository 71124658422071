import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TagsList from "../components/tags"
import { IndexHeader } from "../components/header"

const Tags = ({ pageContext, data }) => {
  const { tag, tagId } = pageContext
  const { edges } = data.allMarkdownRemark

  return (
    <Layout>
      <SEO
        title={tag}
        description={`All things ${tag}`}
        path={`/tags/${tagId}`}
      />
      <section className="max-w-3xl px-5 mx-auto">
        <IndexHeader breadcrumbs={[["Tags", "/tags"]]}>{tag}</IndexHeader>
        <ul className="divide-gray-300 divide-y space-y-4">
          {edges
            .filter(({ node }) => {
              const rawDate = node.frontmatter.rawDate
              const date = new Date(rawDate)
              return date < new Date()
            })
            .map(({ node }) => {
              const { readingTime } = node.fields
              const { title, date, category, tags, path } = node.frontmatter
              const isProject = path.startsWith("/projects/")
              return (
                <li key={node.id} className="py-10">
                  <div>
                    <h4 className="text-gray-500 font-light text-md">
                      {isProject ? "Project" : "Blog Post"}
                    </h4>
                  </div>
                  <Link to={path} className="no-underline group">
                    <div className="mb-3">
                      <div className="mb-1">
                        <h3 className="font-extrabold group-hover:text-highlight-yellow inline leading-tight text-3xl text-primary-500">
                          {title}
                        </h3>
                      </div>

                      <div>
                        <h5 className="inline text-gray-600">{date}</h5>
                        <h5 className="inline text-gray-600">
                          - {readingTime.text}
                        </h5>
                      </div>
                    </div>
                    <p className="text-gray-700">{node.excerpt}</p>
                  </Link>
                  <div className="mt-3 text-xs flex items-center">
                    <span className="text-gray-500 mr-3">Category:</span>
                    <Link
                      to={`/${
                        isProject ? "projects" : "blog"
                      }/categories/${category}`}
                      className="text-secondary-400 hover:text-secondary-600 capitalize"
                    >
                      {category}
                    </Link>
                  </div>
                  <div className="text-xs flex items-center">
                    <span className="text-gray-500 mr-3">Tags:</span>
                    <TagsList tags={tags} />
                  </div>
                </li>
              )
            })}
        </ul>
      </section>
    </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const tagPageQuery = graphql`
  query TagPage($tagId: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          tags: { elemMatch: { tagId: { in: [$tagId] } } }
          draft: { eq: false }
        }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            rawDate: date
            path
            category
            tags {
              tagId
              tag
            }
          }
          excerpt
        }
      }
    }
  }
`

import React from "react"
import kebabCase from "lodash/kebabCase"
import { Link } from "gatsby"
const Tags = props => {
  const { tags, ...rest } = props
  return (
    <ul className="flex space-x-2" {...rest}>
      {tags.map(({ tagId, tag }) => (
        <li key={tagId}>
          <Link to={`/tags/${tagId}/`}>
            <span className="text-secondary-400 hover:text-secondary-600 capitalize">
              {tag}
            </span>
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default Tags
